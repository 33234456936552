(function (Xcelsior) {

    Xcelsior.Search = {

        formData: {},

        getUrl: function () {
            var url = '';

            jQuery.each(Xcelsior.Search.formData, function (key, values) {
                console.log(key, values);

                if (values.length) {
                    if (url !== '') {
                        url += '&';
                    }

                    url += key + '=' + values.join('|');
                }
            });

            return url;
        },

        addFromData: function (key, value) {
            if (typeof Xcelsior.Search.formData[key] === 'undefined') {
                Xcelsior.Search.formData[key] = [];
            }

            Xcelsior.Search.formData[key].push(value);
        },

        init: function () {
            jQuery('[data-select*=":"]').on('click', function (e) {
                e.preventDefault();

                var select = jQuery(this).data('select').split(':');

                jQuery('[name="' + select[0] + '[' + select[1] + ']"]').trigger('click').closest('form').submit();
            });

            if (jQuery('.searchpage').length) {
                jQuery('.search-form').on('submit', function (e) {
                    e.preventDefault();

                    Xcelsior.Search.formData = {};

                    jQuery(this).serializeArray().filter(function (v) {
                        if (!v.value.length) {
                            return;
                        }

                        if (v.name.substring(0, 1) === 'q'){ //v.name.startsWith("q")) {
                            Xcelsior.Search.addFromData('q', v.value);
                        }

                        if (v.name.substring(0, 9) === 'categorie') {//v.name.startsWith("categorie")) {
                            Xcelsior.Search.addFromData('categorie', v.value);
                        }

                        if (v.name.substring(0, 4) === 'merk') {//v.name.startsWith("merk")) {
                            Xcelsior.Search.addFromData('merk', v.value);
                        }

                        if (v.name.substring(0, 6) === 'filter') {//v.name.startsWith("filter")) {
                            Xcelsior.Search.addFromData('filters', v.value);
                        }

                        return v.value;
                    });

                    var url = Xcelsior.Search.getUrl();

                    window.location.href = window.location.origin + window.location.pathname + (url.length ? '?' + url : '');
                });
            }
        }
    };

    Xcelsior.registerModule(jQuery.proxy(Xcelsior.Search.init, Xcelsior.Search));

})(window.Xcelsior);


