(function () {

    /*
     * Page Builder
     */
    var pageBuilder = angular.module('page-builder', ['dndLists']);

    // pageBuilder.filter('stripped', [function () {
    //     return function (val) {
    //         return jQuery.map(val, function (item) {
    //             var result = {};
    //
    //             jQuery.each(item, function (key, value) {
    //
    //                 result[key] = jQuery.map(value, function (item) {
    //                     return {
    //                         'id': item.id,
    //                     };
    //                 });
    //             });
    //
    //             return result;
    //         });
    //     };
    // }]);

    /*
     * Page Builder Controller
     */
    pageBuilder.controller('PageBuilderCtrl', ['$scope', '$element', function ($scope, $element) {

        $scope.columns = {
            1: []
        };

        $scope.$watch('columns[1]', function (model) {
            $scope.modelAsJson = angular.toJson(model, true);
        }, true);

        $scope.addContainer = function () {
            $scope.columns[1].push({
                type:   "container",
                format: {
                    base: [12]
                },
                fluid: false,
                id:     1,
                rows:   [
                    []
                ]
            });

            setTimeout(function () {
                jQuery('.page-builder .container-element [data-toggle="tooltip"]').tooltip();
            }, 100);
        };

        $scope.changeRows = function (item, format) {
            // Remove the rows we don't need
            if (item.rows.length > format.length) {
                item.rows.splice(format.length - item.rows.length, item.rows.length);
            }

            // Add new rows
            if (format.length > item.rows.length) {
                jQuery.each(format, function (key, value) {
                    if (typeof item.rows[key] === 'undefined') {
                        item.rows[key] = [];
                    }
                });
            }

            item.format.base = format;
        };

        $scope.rowColClass = function (container) {
            var length = container.rows.length;

            if (length === 2) {
                return 'col-sm-6';
            }

            if (length === 3) {
                return 'col-sm-4';
            }

            if (length === 4) {
                return 'col-sm-3';
            }

            if (length === 6) {
                return 'col-sm-2';
            }

            if (length === 12) {
                return 'col-sm-1';
            }

            return 'col-sm-12';
        };

        $scope.dragoverCallback = function () {
            return false;
        };

        $scope.rawItems = {
            'card':  {
                type:    'card',
                id:      1,
                name:    window.pageBuilder_i18n.item.card.title,
                icon:    'fa-font',
                style:   'default',
                content: '',
                btn: {
                    page: ''
                }
            },
            'image': {
                type: 'image',
                id:   5,
                name: window.pageBuilder_i18n.item.image.title,
                icon: 'fa-image'
            },
            'survey': {
                type: 'survey',
                id:   6,
                name: window.pageBuilder_i18n.item.survey.title,
                icon: 'fa-clipboard-list'
            }
        };

        $scope.pageSubmit = function () {
            var incompleteWidgets = [];
            if ($scope.columns) {
                jQuery.each($scope.columns, function (columnKey, columnWidgets) {
                    jQuery.each(columnWidgets, function (i, val) {
                        if (val.status && val.status !== 'success') {
                            incompleteWidgets.push(val);
                        }
                    });
                });
            }

            if (incompleteWidgets.length) {
                var content       = document.createElement('div');
                content.innerHTML = window.pageBuilder_i18n.confirmation.submit_incomplete_widgets.text;

                swal({
                    icon:    "warning",
                    title:   window.pageBuilder_i18n.confirmation.submit_incomplete_widgets.title,
                    content: content,
                    buttons: {
                        cancel:  {
                            text:       window.pageBuilder_i18n.confirmation.submit_incomplete_widgets.cancel,
                            value:      null,
                            visible:    true,
                            className:  "",
                            closeModal: true
                        },
                        confirm: {
                            text:       window.pageBuilder_i18n.confirmation.submit_incomplete_widgets.confirm,
                            value:      true,
                            visible:    true,
                            className:  "",
                            closeModal: true
                        }
                    }
                }).then(function (confirm) {
                    if (confirm === true) {
                        jQuery('body').addClass('showing-page-leave');
                        $element.parent('form').submit();
                    }
                });
            } else {
                jQuery('body').addClass('showing-page-leave');
                $element.parent('form').submit();
            }
        };

        var items = [];
        jQuery.map($scope.rawItems, function (val) {
            items.push(val);
        });
        $scope.itemsList = items;
        $scope.items     = [];

        if (typeof inputContainers !== 'undefined') {
            var containerId = 1;
            jQuery.each(inputContainers, function (containerKey, container) {
                container.rows = jQuery.each(container.rows, function (rowKey, rowItems) {
                    return jQuery.each(rowItems, function (key, item) {
                        if (jQuery.inArray(item.type, $scope.rawItems[item.type])) {

                            item.icon = $scope.rawItems[item.type].icon;
                            item.name = $scope.rawItems[item.type].name;

                            if (item.type === 'card' && !item.btn) {
                                item.btn = {
                                    page: ''
                                };
                            }

                            return item;
                        }
                    });
                });

                container.type  = 'container';
                // container.fluid = $scope.rowColClass(container);

                $scope.columns[containerId].push(container);
            });
        }

        // Handels collapsing!
        $scope.collapsed     = [];
        $scope.collapseClick = function (zone, item) {
            $scope.collapsed[zone] = ($scope.collapsed[zone] === item) ? null : item;
        };

        // Handel local content
        jQuery('#input-widget-columns').on('change input', function (event) {
            $scope.$apply(function () {
                $scope.columns[1] = jQuery.parseJSON(event.target.defaultValue);
            });
        });


        // Removes an widget
        $scope.removeItem = function (containerOrRow, item, $event) {
            var elem     = $event.currentTarget || $event.srcElement || $event.target,
                elemItem = jQuery(elem).closest('.widget-item');

            var isEmpty = true;

            if (typeof item === 'undefined' || item === null) {
                jQuery.each(containerOrRow.rows, function (_, row) {
                    if (row.length) {
                        isEmpty = false;
                    }
                });
            } else {
                isEmpty = elemItem.find('[ng-model]:input:not(button)').length == elemItem.find('[ng-model]:input.ng-empty:not(button)').length;
            }

            if (isEmpty) {
                if (typeof item === 'undefined' || item === null) {
                    var index = $scope.columns[1].indexOf(containerOrRow);
                    $scope.columns[1].splice(index, 1);
                } else {
                    var index = containerOrRow.indexOf(item);
                    containerOrRow.splice(index, 1);
                }
            } else {
                swal({
                    icon:    "warning",
                    title:   window.pageBuilder_i18n.confirmation.delete.title,
                    text:    window.pageBuilder_i18n.confirmation.delete.text,
                    buttons: {
                        cancel:  {
                            value:      null,
                            visible:    true,
                            className:  "",
                            closeModal: true
                        },
                        confirm: {
                            value:      true,
                            visible:    true,
                            className:  "",
                            closeModal: true
                        }
                    }
                }).then(function (confirm) {
                    if (confirm === true) {
                        $scope.$apply(function () {
                            if (typeof item === 'undefined' || item === null) {
                                var index = $scope.columns[1].indexOf(containerOrRow);
                                $scope.columns[1].splice(index, 1);
                            } else {
                                var index = containerOrRow.indexOf(item);
                                containerOrRow.splice(index, 1);
                            }
                        });
                    }
                });
            }
        };
    }]).directive('widgetItem', function () {
        return {
            restrict: 'C',
            link:     function (scope) {
                scope.disabled = function (state, elem) {
                    if (elem && jQuery(elem)) {
                        if (jQuery(elem)[0] && jQuery(elem)[0].selectize) {
                            if (state) {
                                jQuery(elem)[0].selectize.disable();
                            } else {
                                jQuery(elem)[0].selectize.enable();
                            }
                        }
                    }
                    return state;
                };
            }
        };
    }).directive('itemSelectize', function () {
        return {
            restrict: 'C',
            link:     function (scope, elem) {
                for (var selectize in window.Xcelsior.Selectize.selectizes) {
                    if (window.Xcelsior.Selectize.selectizes.hasOwnProperty(selectize)) {
                        var value    = window.Xcelsior.Selectize.selectizes[selectize],
                            selector = 'dynamic-selectize-' + selectize;

                        if (selectize === 'default') {
                            selector = 'selectize';
                        }

                        if (elem.hasClass(selector)) {
                            var el            = elem,
                                opts          = {},
                                selectOptions = elem.data('options');

                            if (selectOptions) {
                                opts.settings = selectOptions;
                            }

                            jQuery.extend(true, opts, window.Xcelsior.Selectize.selectizes.default, value);

                            var itemBtnPage    = scope.item.btn.page,
                                itemBtnPageIds = itemBtnPage ? itemBtnPage.split(value.delimiter) : [];

                            jQuery.each(itemBtnPageIds, function (index, id) {
                                if (scope.item.btn.pageData) {
                                    itemBtnPage[index] = scope.item.btn.pageData[id];
                                }
                            });

                            if (elem.data('selectize-params')) {
                                opts.params = elem.data('selectize-params');
                            }

                            if (elem.data('max-items')) {
                                opts.maxItems = elem.data('max-items');
                            }

                            var itemOptions = [];
                            if (scope.item.btn.pageData) {
                                jQuery.map(scope.item.btn.pageData, function (val) {
                                    itemOptions.push(val);
                                });
                            }

                            opts.items   = itemBtnPageIds ? itemBtnPageIds : [];
                            opts.options = itemOptions;

                            opts.onOptionAdd = function (item, option) {
                                if (!scope.item.btn.pageData) {
                                    scope.item.btn.pageData = {};
                                }
                                scope.item.btn.pageData[item] = option;
                            };

                            // Fixes angular to notic value change!
                            opts.onChange = function () {
                                elem.trigger('input');
                            };

                            el.selectize(opts);
                        }
                    }
                }
            }
        };
    }).directive('itemFilepicker', function () {
        return {
            restrict: 'C',
            link:     function (scope, elem) {
                scope.$watch('item.file', function (n, o) {
                    var url             = elem.find('[data-picker-preview]').attr('src');
                    scope.item.file_url = url === '#' ? '' : url;

                    if (scope.item.isInit === true) {
                        var pickerId          = elem.find('[data-picker]').data('picker'),
                            preview           = elem.find('[data-picker-preview="' + pickerId + '"]'),
                            previewIcon       = elem.find('[data-picker-preview-icon="' + pickerId + '"] i'),
                            previewProcessing = elem.find('[data-picker-preview-processing="' + pickerId + '"]');

                        scope.item.file_icon = previewIcon.data('icon');

                        setTimeout(function () {
                            scope.$apply();

                            scope.item.preview_is_processing = !previewProcessing.hasClass('hidden');
                            if (scope.item.preview_is_processing === true) {
                                scope.item.file_url = null;
                            }

                            elem.find('[ng-model="item.title"]').val((typeof scope.item.title !== 'undefined' ? scope.item.title : '') + "1").trigger('input');
                            elem.find('[ng-model="item.title"]').val(scope.item.title.slice(0, -1)).trigger('input');
                        }, 10);
                    }
                    scope.item.isInit = true;

                    if (!n) {
                        scope.item.file_url              = null;
                        scope.item.preview_is_processing = false;
                    }
                });

                if (window.Xcelsior.Picker.modal.length > 0) {
                    window.Xcelsior.Picker.modal.mediapicker({
                        api:         window.HOME + '/media/api',
                        csrf:        window.CSRF,
                        multiSelect: false
                    });

                    elem.on('click', '[data-picker]', window.Xcelsior.Picker.pick)
                        .on('click', '[data-picker-clear]', window.Xcelsior.Picker.clear);
                }

            }
        };
    }).directive('itemEditor', function () {
        return {
            restrict: 'C',
            link:     function (scope, elem) {

                var mediaButton = elem.find('button.media-button'),
                    summernote  = elem.find('div.contenteditor'),
                    target      = elem.find('textarea.contenteditor-target');

                if (summernote.length > 0) {
                    // Temporary solution https://github.com/summernote/summernote/issues/2017
                    if (!!document.createRange) {
                        document.getSelection().removeAllRanges();
                    }

                    summernote.html(scope.item.content).summernote({
                        disableDragAndDrop: true, // Makes a mess!
                        lang:               ((window.LOCALE === 'nl') ? 'nl-NL' : 'en-US'),
                        minHeight:          300,
                        dialogsInBody: true,
                        toolbar:            [
                            [
                                'style', ['style', 'bold', 'italic', 'underline', 'strikethrough', 'clear']
                            ],
                            [
                                'para', ['ul', 'ol', 'paragraph']
                            ],
                            [
                                'media', ['link', 'filepicker']
                            ],
                            [
                                'options', ['codeview']
                            ]
                        ],
                        styleWithSpan:      false,
                        callbacks:          {
                            onInit:  function () {
                                summernote.summernote('removeModule', 'autoLink');
                            },
                            onPaste: function () {
                                setTimeout(function () {
                                    console.log(clean_pasted_html(summernote.summernote('code')));
                                    summernote.summernote('code', clean_pasted_html(summernote.summernote('code')));
                                }, 10);
                            }
                        }
                    });

                    // console.log(scope.item.content);
                    elem.find('.dropdown-toggle').dropdown();

                    if (target.length > 0) {
                        summernote.on('summernote.change', function () {
                            var value = summernote.summernote('code');

                            target.val(value).trigger('change').trigger('input');
                        });

                        summernote.trigger('summernote.change');

                        var linkDialog = jQuery('.link-dialog');

                        linkDialog.find('.checkbox').find('input').addClass('m-r-5');

                        linkDialog.find('.checkbox').removeClass('checkbox');
                    }

                    if (summernote.summernote('isEmpty')) {
                        target.val('').trigger('input');
                    }

                    if (mediaButton.length > 0) {
                        mediaButton.click(function (e) {
                            e.preventDefault();

                            var button = elem.find('[data-pick]');

                            if (button.length > 0) {
                                button.parent().trigger('click');
                            }

                            return false;
                        });
                    }
                }
            }
        };
    });

})();
