/**
 * Helper function to clean pasted HTML
 */
function clean_pasted_html(input) {
    // 1. remove line breaks / Mso classes
    var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
    var output         = input.replace(stringStripper, ' ');

    // 2. strip Word generated HTML comments
    var commentSripper = new RegExp('<!--(.*?)-->', 'g');
    output             = output.replace(commentSripper, '');

    // 3. remove tags leave content if any
    var tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi');
    output          = output.replace(tagStripper, '');

    // 4. Remove everything in between and including tags '<style(.)style(.)>'
    var badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'div'];

    for (var i = 0; i < badTags.length; i++) {
        tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi');
        output      = output.replace(tagStripper, '');
    }

    // 5. remove attributes ' style="..."'
    var badAttributes = ['start', 'onClick'];

    for (var x = 0; x < badAttributes.length; x++) {
        var attributeStripper = new RegExp(' ' + badAttributes[x] + '="(.*?)"', 'gi');
        output                = output.replace(attributeStripper, '');
    }

    return output;
}

/**
 * Summernote uploader plugin
 */
//var tmpl   = jQuery.summernote.renderer.getTemplate(),
//    editor = jQuery.summernote.eventHandler.getModule('editor'),

var picker = jQuery('#CipeMotionMediaPickerModal');

if (picker.length > 0) {
    picker.mediapicker({
        api:         window.HOME + '/media/api',
        csrf:        window.CSRF,
        multiSelect: false,
        cancelled:   function () {
            // let's ignore this one :)
        }
    });
}

// Extends plugins for adding hello.
//  - plugin is external module for customizing.
jQuery.extend(jQuery.summernote.plugins, {

    'filepicker': function (context) {
        // ui has renders to build ui elements.
        //  - you can create a button with `ui.button`
        var ui = jQuery.summernote.ui;

        // add hello button
        context.memo('button.filepicker', function () {
            // create button
            var button = ui.button({
                contents: '<i class="fas fa-image" data-pick="true"/>',
                tooltip:  window.i18n.summernote.tooltip.insert_media,
                click:    function () {
                    context.invoke('filepicker.pick');
                }
            });

            return button.render();
        });

        this.pick = function () {
            if (picker.length > 0) {
                context.invoke('editor.saveRange');

                picker.mediapicker().pick(function (media) {
                    context.invoke('editor.restoreRange');

                    if (media[0].type === 'image') {
                        context.invoke('editor.insertImage', media[0].url);

                        jQuery('img[src="' + media[0].url + '"]:not([alt])')
                            .attr('alt', media[0].name)
                            .attr('title', media[0].name);
                    } else {
                        context.invoke('editor.createLink', {
                            url:       media[0].url,
                            text:      media[0].name,
                            newWindow: true
                        });
                    }
                });
            }
        };
    }

});

/**
 * Summernote editor
 */
var mediaButton = jQuery('button.media-button'),
    summernote  = jQuery('div.contenteditor'),
    target      = jQuery('textarea.contenteditor-target');

if (summernote.length > 0) {
    var toolbar = [
        [
            'style', ['style', 'bold', 'italic', 'underline', 'strikethrough', 'clear']
        ],
        [
            'para', ['ul', 'ol', 'paragraph']
        ],
        [
            'media', ['link', 'filepicker']
        ],
        [
            'options', ['codeview']
        ]
    ];

    if (jQuery('.summernote-wrapper').hasClass('list-editor')) {
        toolbar = [
            [
                'para', ['ul', 'ol']
            ]
        ];
    }
    summernote.summernote({
        lang:          'nl-NL',
        minHeight:     300,
        toolbar:       toolbar,
        styleWithSpan: false,
        imageTitle:    {
            specificAltField: true
        },
        dialogsInBody: true,
        popover:       {
            image: [
                ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                ['float', ['floatLeft', 'floatRight', 'floatNone']],
                ['remove', ['removeMedia']],
                ['custom', ['imageTitle']]
            ]
        },
        callbacks:     {
            onInit:  function () {
                summernote.summernote('removeModule', 'autoLink');
            },
            onPaste: function () {
                setTimeout(function () {
                    summernote.summernote('code', clean_pasted_html(summernote.summernote('code')));
                }, 10);
            }
        }
    }).removeClass('hidden');

    if (target.length > 0) {
        if (target.val().length > 0) {
            summernote.summernote('code', clean_pasted_html(target.val()));
        }

        summernote.on('summernote.change', function () {
            var value = summernote.summernote('code');

            target.val(value).trigger('change');
        });

        summernote.trigger('summernote.change');

        // Link dialog fixes
        var linkDialog = jQuery('.link-dialog');

        linkDialog.find('.checkbox').find('input').addClass('m-r-5');

        linkDialog.find('.checkbox').removeClass('checkbox');
    }

    if (mediaButton.length > 0) {
        mediaButton.click(function (e) {
            e.preventDefault();

            var button = jQuery('[data-pick]');

            if (button.length > 0) {
                button.parent().trigger('click');
            }

            return false;
        });
    }
}
