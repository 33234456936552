(function () {

    // Init Xcelsior and all modules
    angular.module('xcelsior', [

        // Modules
        'mediapicker',
        'page-builder'

    ]);

})();
