(function (Xcelsior) {

    Xcelsior.Util = {

        confirmClick: function (e) {
            e.preventDefault();

            var elem   = jQuery(this),
                target = elem.attr('href'),
                method = (elem.data('confirm')) ? elem.data('confirm') : 'GET';

            swal({
                icon:                (elem.data('type') ? elem.data('type') : 'warning'),
                title:               window.i18n.msg_confirm_title,
                text:                (elem.data('msg') ? elem.data('msg') : null),
                showLoaderOnConfirm: true,
                buttons:             {
                    cancel:  {
                        text:       window.i18n.btn_cancel,
                        value:      null,
                        visible:    true,
                        className:  "",
                        closeModal: true
                    },
                    confirm: {
                        text:       window.i18n.btn_confirm,
                        value:      true,
                        visible:    true,
                        className:  "",
                        closeModal: true
                    }
                }
            }).then(function (confirm) {
                if (confirm) {
                    if (jQuery.inArray(method.toUpperCase(), ['DELETE', 'POST']) !== -1) {
                        jQuery('body').append(jQuery(
                            '<form id="_confirm" action="' + target + '" method="POST" style="display:none;">' +
                            '<input type="hidden" name="_token" value="' + window.CSRF + '" />' +
                            '<input type="hidden" name="_method" value="' + method.toUpperCase() + '" />' +
                            '</form>'
                        ));

                        document.forms['_confirm'].submit();
                    } else {
                        window.location.href = target;
                    }
                }
            });
        },

        setCookie: function(cname, cvalue, exdays) {
            var d = new Date();

            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

            var expires     = "expires=" + d.toUTCString();

            document.cookie = cname + "=" + cvalue + "; domain=" + document.domain + "; " + expires + ";path=/";
        },

        getAPIUrl: function (url) {
            return window.API.URL + url;
        },

        isScrolledIntoView: function (elem) {
            var docViewTop    = jQuery(window).scrollTop();
            var docViewBottom = docViewTop + jQuery(window).height();

            var elemTop    = jQuery(elem).offset().top;
            var elemBottom = elemTop + jQuery(elem).height();

            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        },

        slugify: function (string) {
            return string.toLowerCase()
                .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
                .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single -
                .replace(/^-+|-+$/g, ''); // remove leading, trailing -
        },

        getAddressData: function (address) {
            return jQuery.get('//maps.google.com/maps/api/geocode/json', {
                address: encodeURI(address),
                sensor:  false
            });
        }

    };

})(window.Xcelsior);
